const translations = {
  en: {
    autofill: 'autofill | autofills',
    autofills: 'autofill | autofills',
    browser: 'browser | browsers',
    browsers: 'browser | browsers',
    conversion_path: 'conversion path | conversion paths',
    country: 'country | countries',
    countries: 'country | countries',
    device: 'device | devices',
    devices: 'device | devices',
    error: 'error | errors',
    errors: 'error | errors',
    utm_code: 'top source | top sources',
    utm_codes: 'top source | top sources',
    smart_tooltip: 'smart tooltip | smart tooltips',
    smart_tooltips: 'smart tooltip | smart tooltips',
    custom_dimension: 'your custom dimension | your custom dimensions',
    custom_dimensions: 'your custom dimension | your custom dimensions',

    initial_abandoners: 'Initial abandoners',
    submits_failed: 'Submit failed',
    initial_submit_but_abandoned: 'Initial submits but abandoned',
    resubmit_success: 'Re-submit success',
    converters: 'Converters',

    start_your_free_trial_today: 'Start your free trial today',
    start_15day_trial: 'Start your 15-day free trial',
    no_cc_required: 'No credit card required',
    all_features_and_functionalities: 'All features and functionalities',
    fifteen_days_free: '15-days free of charge',
    no_code_setup: 'No-code setup',
    hundred_percent_gdpr_compliant: '100% GDPR compliant',
    company_references_intro_text: 'Companies worldwide, from startups to the biggest corporations use Exatom to optimise their forms.',
    questions_we_have_answers: 'Questions? We have answers, just <a href="mailto:cs@exatom.io?subject=Signup%20question">email us!</a>',

    your_name: 'Your name',
    work_email_address: 'Work email address',
    your_work_email_address: 'Your work email address',
    signup_tc_accept_text: 'By registering, you agree to our <a href="https://exatom.io/terms-and-conditions/" target="_blank">Terms and Conditions</a> and <a href="https://exatom.io/services-privacy-policy/" target="_blank">Services Privacy Policy</a>',
    start_free_trail_button: 'Start my free trail',

    thanks_for_signing_up: 'Thanks for signing up',
    we_sent_an_email_to: 'We\'ve sent an email to',
    signup_activation_cta_text: 'Click on the <strong>Getting started</strong> button in our email to activate your account.',

    signup_tech_error: 'Hmm, we didn\'t expect this. A technical error occurred, please ty again later.',
    'Please enter a valid email address': 'Please enter a valid email address',
    'Please enter a valid email address, this domain is not setup to receive email': 'Please enter a valid email address, this domain is not setup to receive email',
    'Please use your work email address': 'Please use your work email address',
  },
  nl: {
    start_your_free_trial_today: 'Begin vandaag je gratis proefperiode',
    start_15day_trial: 'Begin je 15-daagse gratis proefperiode',
    no_cc_required: 'Geen creditcard nodig',
    all_features_and_functionalities: 'Alle functies en mogelijkheden',
    fifteen_days_free: '15 dagen gratis',
    no_code_setup: 'No-code setup',
    hundred_percent_gdpr_compliant: '100% AVG-conform',
    company_references_intro_text: 'Wereldwijd maken bedrijven, van startups tot de grootste ondernemingen, gebruik van Exatom om hun formulieren te optimaliseren.',
    questions_we_have_answers: 'Vragen? Wij hebben antwoorden, stuur ons gewoon een <a href="mailto:cs@exatom.io?subject=Signup%20question">e-mail!</a>',

    your_name: 'Je naam',
    work_email_address: 'Zakelijk e-mailadres',
    your_work_email_address: 'Je zakelijke e-mailadres',
    signup_tc_accept_text: 'Door te registreren, ga je akkoord met onze <a href="https://exatom.io/terms-and-conditions/" target="_blank">Algemene Voorwaarden</a> en <a href="https://exatom.io/services-privacy-policy/" target="_blank">Privacyverklaring voor Diensten</a>',
    start_free_trail_button: 'Start mijn gratis proefperiode',

    thanks_for_signing_up: 'Bedankt voor je aanmelding',
    we_sent_an_email_to: 'We hebben een e-mail gestuurd naar',
    signup_activation_cta_text: 'Klik op de <strong>Aan de slag</strong> knop in onze e-mail om je account te activeren.',

    signup_tech_error: 'Hm, dit hadden we niet verwacht. Er is een technische fout opgetreden, probeer het later opnieuw.',
    'Please enter a valid email address': 'Voer een geldig e-mailadres in',
    'Please enter a valid email address, this domain is not setup to receive email': 'Voer een geldig e-mailadres in, dit domein is niet geconfigureerd om e-mail te ontvangen',
    'Please use your work email address': 'Gebruik je zakelijke e-mailadres',
  },
  fr: {
    start_your_free_trial_today: 'Commencez votre essai gratuit dès aujourd’hui',
    start_15day_trial: 'Commencez votre essai gratuit de 15 jours',
    no_cc_required: 'Aucune carte de crédit requise',
    all_features_and_functionalities: 'Toutes les fonctionnalités',
    fifteen_days_free: '15 jours gratuits',
    no_code_setup: 'Aucune configuration par code',
    hundred_percent_gdpr_compliant: '100% conforme au RGPD',
    company_references_intro_text: 'Des entreprises du monde entier, des startups aux plus grandes sociétés, utilisent Exatom pour optimiser leurs formulaires.',
    questions_we_have_answers: 'Des questions? Nous avons les réponses, envoyez-nous simplement un <a href="mailto:cs@exatom.io?subject=Signup%20question">e-mail!</a>',

    your_name: 'Votre nom',
    work_email_address: 'Adresse e-mail professionnelle',
    your_work_email_address: 'Votre adresse e-mail professionnelle',
    signup_tc_accept_text: 'En vous inscrivant, vous acceptez nos <a href="https://exatom.io/terms-and-conditions/" target="_blank">Conditions Générales</a> et notre <a href="https://exatom.io/services-privacy-policy/" target="_blank">Politique de Confidentialité des Services</a>',
    start_free_trail_button: 'Commencer mon essai gratuit',

    thanks_for_signing_up: 'Merci pour votre inscription',
    we_sent_an_email_to: 'Nous avons envoyé un e-mail à',
    signup_activation_cta_text: 'Cliquez sur le bouton <strong>Commencer</strong> dans notre e-mail pour activer votre compte.',

    signup_tech_error: 'Hum, nous ne nous attendions pas à cela. Une erreur technique s’est produite, veuillez réessayer plus tard.',
    'Please enter a valid email address': 'Veuillez saisir une adresse e-mail valide',
    'Please enter a valid email address, this domain is not setup to receive email': 'Veuillez saisir une adresse e-mail valide, ce domaine n’est pas configuré pour recevoir des e-mails',
    'Please use your work email address': 'Veuillez utiliser votre adresse e-mail professionnelle',
  },
  de: {
    start_your_free_trial_today: 'Starten Sie noch heute Ihre kostenlose Testphase',
    start_15day_trial: 'Starten Sie jetzt Ihre 15-tägige kostenlose Testphase',
    no_cc_required: 'Keine Kreditkarte erforderlich',
    all_features_and_functionalities: 'Alle Funktionen und Features',
    fifteen_days_free: '15 Tage kostenlos',
    no_code_setup: 'Keine Code-Einrichtung',
    hundred_percent_gdpr_compliant: '100% DSGVO-konform',
    company_references_intro_text: 'Unternehmen weltweit, von Start-ups bis hin zu Großkonzernen, nutzen Exatom, um ihre Formulare zu optimieren.',
    questions_we_have_answers: 'Fragen? Wir haben Antworten! Senden Sie uns einfach eine <a href="mailto:cs@exatom.io?subject=Signup%20question">E-Mail!</a>',

    your_name: 'Ihr Name',
    work_email_address: 'Geschäftliche E-Mail-Adresse',
    your_work_email_address: 'Ihre geschäftliche E-Mail-Adresse',
    signup_tc_accept_text: 'Mit Ihrer Registrierung stimmen Sie unseren <a href="https://exatom.io/terms-and-conditions/" target="_blank">Allgemeinen Geschäftsbedingungen</a> und unserer <a href="https://exatom.io/services-privacy-policy/" target="_blank">Datenschutzerklärung für Dienste</a> zu',
    start_free_trail_button: 'Mein kostenloses Testabo starten',

    thanks_for_signing_up: 'Vielen Dank für Ihre Anmeldung',
    we_sent_an_email_to: 'Wir haben eine E-Mail gesendet an',
    signup_activation_cta_text: 'Klicken Sie auf den Button <strong>Erste Schritte</strong> in unserer E-Mail, um Ihr Konto zu aktivieren.',

    signup_tech_error: 'Hmm, damit haben wir nicht gerechnet. Ein technischer Fehler ist aufgetreten, bitte versuchen Sie es später noch einmal.',
    'Please enter a valid email address': 'Bitte geben Sie eine gültige E-Mail-Adresse ein',
    'Please enter a valid email address, this domain is not setup to receive email': 'Bitte geben Sie eine gültige E-Mail-Adresse ein, diese Domain ist nicht für den Empfang von E-Mails eingerichtet',
    'Please use your work email address': 'Bitte verwenden Sie Ihre geschäftliche E-Mail-Adresse',
  }
}
export { translations }
